import axios from 'axios'
import { Event, Link, News, Post, Report, Role, Share, ShareType, Team } from './models'
import * as firebase from 'firebase/auth'
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from "firebase/firestore"
import { convertTimestampToDate } from './utils'

const CFPath = process.env.REACT_APP_CF_PATH
// get api according to environment
const getApi = (path: string) => ({
  'development': `${CFPath}/${path}`,
  'production': `https://${path.toLocaleLowerCase()}-${CFPath}`,
  'test': ``
}[process.env.NODE_ENV])
const client = axios.create({})
client.interceptors.request.use(async (config) => {
  const token = await firebase.getAuth().currentUser?.getIdToken()
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export const getMyRoles = async () => {
  const { data } = await client.post<Role[]>(getApi('getMyRoles'))
  return data as Role[]
}

export const getTeam = async (team: string) => {
  const _doc = await getDoc(doc(getFirestore(), "teamProfiles", team))
  let _team = _doc.data() as Team
  _team = convertTimestampToDate(_team)
  return _team
}

type UpdateTeamBody = { team: string, description: string, image?: string, url?: string }
export const updateTeam = async (body: UpdateTeamBody) => {
  const { data } = await client.post<Team>(getApi('updateTeam'), body)
  return data
}

export const getEvents = async (team: string, last?: Event) => {
  const whereArgs = team === '管理員' ? [] : [where("team", "==", team)]
  const q = query(collection(
    getFirestore(), "events"),
    limit(20),
    ...whereArgs
  )
  const docs = await getDocs(q)
  const data = docs.docs.map(doc => {
    let event = doc.data() as Event
    event.id = doc.id
    event = convertTimestampToDate(event)
    return event
  })
  return data
}

type UpdateEventBody = Partial<Event>
export const updateEvent = async (event: Partial<Event>) => {
  const body: UpdateEventBody = event
  const { data } = await client.post<Event[]>(getApi('updateEvent'), body)
  return data
}

type CreateEventBody = Partial<Event>
export const createEvent = async (event: Partial<Event>) => {
  const body: CreateEventBody = event
  const { data } = await client.post<Event[]>(getApi('createEvent'), body)
  return data
}

export const deleteEvent = async ({ id, team }: { id: string, team: string }) => {
  await client.post(getApi('deleteEvent'), { id, team })
}

type GetReportsBody = { last?: Report }
export const getReports = async (last?: Report) => {
  const body: GetReportsBody = { last }
  const { data } = await client.post<Report[]>(getApi('getReports'), body)
  return data
}

type UpsertReportBody = { dataUrl: string }
export const upsertReport = async (dataUrl: string) => {
  const body: UpsertReportBody = { dataUrl }
  const { data } = await client.post(getApi('upsertReport'), body)
  return data
}

const GetSharePath: Record<ShareType, string> = {
  'share-wk': 'getSharesWK',
  'share-mc': 'getSharesMC',
}

type GetShareBody = { last?: Share }
export const getShares = async (type: ShareType, last?: Share) => {
  const body: GetShareBody = { last }
  const { data } = await client.post<Share[]>(getApi(GetSharePath[type]), body)
  return data
}

type UpsertShareCMBody = { url: string }
export const upsertShareCM = async (url: string) => {
  const body: UpsertShareCMBody = { url }
  const { data } = await client.post<Share[]>(getApi('upsertShareMC'), body)
  return data
}

export const getPosts = async (team: string, last?: Post) => {
  const q = query(
    collection(getFirestore(), "posts"),
    where("team", "==", team),
    orderBy("date", "desc")
  )
  const docs = await getDocs(q)
  const data = docs.docs.map(doc => {
    let post = doc.data() as Post
    post.id = doc.id
    post = convertTimestampToDate(post)
    return post
  })
  return data
}

type UpdatePostBody = Partial<Post>
export const updatePost = async (post: Partial<Post>) => {
  const body: UpdatePostBody = post
  const { data } = await client.post<Post[]>(getApi('updatePost'), body)
  return data
}

type CreatePostBody = Partial<Post>
export const createPost = async (post: Partial<Post>) => {
  const body: CreatePostBody = post
  const { data } = await client.post<Post[]>(getApi('createPost'), body)
  return data
}

export const deletePost = async ({ id, team }: { id: string, team: string }) => {
  await client.post(getApi('deletePost'), { id, team })
}

export const syncShareWK = async () => {
  await client.post(getApi('syncShareWK'))
}

export const syncNews = async () => {
  await client.post(getApi('syncNews'))
}

export const getNews = async () => {
  const collectionRef = collection(getFirestore(), 'news');
  const docRef = doc(collectionRef, 'latest');
  const docSnap = await getDoc(docRef);
  try {
    const data = Object.values(docSnap.data() as any) as News[]
    return data ?? []
  } catch (error) {
    return []
  }
}

export const addLink = async ({ type, url }: { type: string, url: string }) => {
  await client.post(getApi('createLink'), { type, url })
}

export const getLinks = async () => {
  const collectionRef = collection(getFirestore(), 'links');
  // query all documents
  const docs = await getDocs(collectionRef)
  const data = docs.docs.map(doc => convertTimestampToDate(doc.data()))
  return data as Link[]
}