import { Timestamp } from "firebase/firestore"
import * as imageConversion from "image-conversion"
import { isNil } from "lodash"

export const toDataUrl = async (blob: Blob) => {
  const reader = new FileReader()
  return new Promise<string>((resolve) => {
    reader.onload = (e) => { resolve(e.target?.result as string) }
    reader.readAsDataURL(blob)
  })
}
export const compressConvertImage = async (file?: File) => {
  if (file) {
    const blob = await imageConversion.compress(file, {
      // maxSize: 500kb
      quality: file.size > 500_000 ? 500_000 / file.size : 1,
      type: imageConversion.EImageType.JPEG,
    })
    return toDataUrl(blob)
  }
  return undefined
}

export const convertTimestampToDate = (data: any) => {
  const fields = Object.keys(data).filter(key => !isNil(data[key]?.seconds))
  fields.forEach(key => {
    const timestamp = data[key]
    if (timestamp) {
      data[key] = new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
    }
  })
  return data
}

export const attempt = async <T>(fn: (i: number) => Promise<T>, retry = 3, delay = 1000): Promise<T> => {
  try {
    return await fn(retry)
  } catch (error) {
    if (retry > 0) {
      await new Promise(resolve => setTimeout(resolve, delay))
      return await attempt(fn, retry - 1, delay)
    }
    throw error
  }
}